import React from 'react'
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import AboutArea from "@containers/index-processing/about-area"
import PageHeader from "@components/pageheader";
import ContactArea from "@containers/index-infotechno/contact-area"
import slide1 from "@assets/images/training5.png"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

const TecknicalAdvice = ({location,pageContext}) => {

    const intl = useIntl()
    const serviceTitles = intl.formatMessage({
      id: "training_and_qualifying",
    })
    const content = [
      {
        image: slide1,
      }
      
    ]
  
    const srviceTexts = [
      {
        text: <><FormattedMessage id="training_and_qualifications" /></>,
      },
      
         
    ]
    return ( 
        <Layout location={location}>
        <Seo title="Services" />
        <Header />
        <main className="site-wrapper-reveal">
        <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="services"

		/>
          <AboutArea
            content={content}
            srviceTexts={srviceTexts}
           serviceTitles={serviceTitles}
           studyRequestUrl="/consultation-request-training-and-qualifications"
         isStudyRequest={true}
         buttonTextRequest="technical_advice_request"
         stopPointer="stopPointer"
          />
  
          <ContactArea />
        </main>
        <Footer />
      </Layout> 
     );
}
 
export default TecknicalAdvice;